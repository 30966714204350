import React from 'react'
import Layout from '../layouts'
import styled from '@emotion/styled'

import ReactGA from 'react-ga'

const Sup = styled.sup`
  color: red;
  position: relative;
  top: 3px;
  margin-left: 2px;
`

const Fieldset = styled.fieldset`
  padding: 0 !important;
  border: none !important;
`

const Legend = styled.p`
  text-align: right;
  font-size: 12px;
  margin-bottom: 5px !important;
`

const Label = styled.label`
  cursor: pointer;
`

const App = () => {
  // const [msg, setMsg] = React.useState('')
  const formRef = React.useRef()

  const track = () => {
    if (formRef && formRef.current.checkValidity()) {
      ReactGA.event({
        category: 'Kontakformular',
        action: 'abgesendet',
      })
    }
  }

  return (
    <Layout>
      <div className="post single page type-page status-publish hentry">
        <div className="post-header">
          <h1 className="post-title">Schreib mir eine Nachricht</h1>
        </div>
        <article className="post-content">
          <Fieldset>
            <Legend>
              <Sup>*</Sup> Pflichtfelder
            </Legend>
            {/* <legend>Fieldset</legend> */}
            <form
              ref={formRef}
              name="contact"
              method="POST"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              action="/formular-versendet/"
            >
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
              <p>
                <Label htmlFor="text_field">
                  Dein Name<Sup>*</Sup>
                </Label>
                <br />
                <input id="text_field" type="text" name="name" required />
              </p>
              <p>
                <Label htmlFor="text_field2">
                  Deine E-Mail-Adresse<Sup>*</Sup>
                </Label>
                <br />
                <input id="text_field2" type="email" name="email" required />
              </p>
              <p>
                <Label htmlFor="text_area">
                  Deine Nachricht<Sup>*</Sup>
                </Label>
                <br />
                <textarea id="text_area" name="message" required></textarea>
              </p>
              <p>
                <Label htmlFor="text_field3">Deine Telefonnummer</Label>
                <br />
                <input id="text_field3" type="tel" name="telefon" />
              </p>
              <p>
                <input
                  className="button"
                  type="submit"
                  class="hover:bg-baseDark"
                  onClick={track}
                  value="Nachricht versenden"
                />
              </p>
            </form>
          </Fieldset>
        </article>
      </div>
    </Layout>
  )
}

export default App
